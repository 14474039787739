<template>
    <div>
        <div class="search">
            <input type="search" class="form-control mb-3" v-model="search" @keyup.enter="searchData" :placeholder="'Press Enter to Search... ' + searchableHeaders" />
        </div>

        <div class="table-responsive">
            <table class="table table-bordered table-hover">
                <thead>
                    <tr>
                        <th v-for="header in headers" :key="header.value">{{ header.label }}</th>
                        
                        <th v-if="isGroupsActions">Actions</th> <!-- Extra column header -->
                    </tr>
                </thead>
                <tbody>
                    <template v-if="loading">
                        <tr>
                            <td :colspan="colspanAccumulated" class="text-center">
                                <b-spinner label="Loading..."></b-spinner>
                            </td>
                        </tr>
                    </template>
                    <template v-if="data.length <= 0">
                        <tr>
                            <td :colspan="colspanAccumulated" class="text-center">
                                <b>No Data Available</b>
                            </td>
                        </tr>
                    </template>
                    <template v-else>
                        <tr v-for="(item, index) in data" :key="index">
                            
                            <td v-for="header in headers" :key="header.value">{{ (header.formatter) ? header.formatter(item[header.value]) : item[header.value] }}</td>
                            <td v-if="isGroupsActions" class="text-center">
                                <slot name="extraColumn" :item="item" :index="index">
                                </slot>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
            <div class="pagination">
                <button type="button" @click="previousPage" :disabled="currentPage === 1" class="btn btn-primary btn-sm">Previous</button>
                <span>Page {{ (totalPages > 0 ) ? currentPage : 0 }} of {{ totalPages }}</span>
                <button type="button" @click="nextPage" :disabled="currentPage === totalPages" class="btn btn-primary btn-sm">Next</button>
            </div>
        </div>
    </div>
</template>

<script>
import axiosAdmin from '../api/axiosAdmin';

export default {
    props: {
        apiUrl: {
            type: String,
            required: true
        },
        headers: {
            type: Array,
            required: true
        },
        rowsPerPage: {
            type: Number,
            default: 10
        },
        optionsPerPage : {
            type: Array,
            default: () => [10, 25, 50]
        },
        isGroupsActions : {
            type: Boolean,
            default : false
        },
        additionalFilters : {
            type : Object,
            default : () => {}
        }
    },
    data() {
        return {
            data: [],
            currentPage: 1,
            totalPages: 1,
            totalItems: 0,
            search : '',
            loading: false
        };
    },
    computed : {
        searchableHeaders() {
            return this.headers.filter(header => header?.searchable !== false).map(header => `[${header.label}]`).join(' ');
        },
        colspanAccumulated() {
            return this.headers.length + (this.isGroupsActions ? 1 : 0);
        }
    },
    methods: {
        async fetchData() {
            try {
                this.loading = true;
                const response = await axiosAdmin.get(this.apiUrl, {
                    params: {
                        page: this.currentPage,
                        per_page: this.rowsPerPage,
                        search : this.search.trim(),
                        ...this.additionalFilters
                    }
                });
                this.data = response.data?.results?.data; // Adjust based on API response structure
                this.totalItems = response.data?.results?.recordsTotal; // Adjust based on API response structure
                this.totalPages = Math.ceil(this.totalItems / this.rowsPerPage);
            } catch (error) {
                window.console.error('Error fetching data:', error);
            } finally {
                this.loading = false;
            }
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
                this.fetchData();
            }
        },
        previousPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
                this.fetchData();
            }
        },
        searchData() {
            this.currentPage = 1;
            this.fetchData();
        }
    },
    watch: {
        currentPage: 'fetchData',
        additionalFilters: 'fetchData',
    },
    created() {
        this.fetchData();
    }
};
</script>

<style scoped>
table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    border: 1px solid #ddd;
    padding: 8px;
}

th {
    background-color: #f4f4f4;
}

.pagination {
    margin-top: 10px;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
</style>