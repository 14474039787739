<script>
    import Layout from '../../../layouts/main'
    import PageHeader from '@/components/page-header'

    import { urlBackend } from '../../../../helpers/global';
    
    import DataTable from '../../../../components/data-table.vue';
    import moment from 'moment';

    export default {
        components: { Layout, PageHeader, DataTable },
        data() {
            return {
                title : 'Billings',
                items: [
                    {
                        text: "Payment",
                    },
                    {
                        text: "Billings",
                        href: "/ss/admin/payment/billings",
                        active: true,
                    },
                ],
                loadingBilling : false,
                headers : [
                    {
                        label : 'Student Name',
                        value : 'nama_siswa',
                    },    
                    {
                        label : 'ID Billing',
                        value : 'order_id'
                    },
                    {
                        label : 'Gross Amount',
                        value : 'gross_amount',
                        formatter : (value) => {
                            return this.$root.rupiah(value)
                        }
                    },
                    {
                        label : 'Status',
                        value : 'transaction_status',
                        searchable : false
                    },
                    {
                        label : 'Transaction Date',
                        value : 'created_at',
                        formatter : (value) => {
                            return moment(value).locale('id').format('DD MMM YYYY, HH:mm')
                        },
                        searchable : false
                    },
                ],
                urlBackend : urlBackend,
                transactionStatusOptions : [
                    {
                        value : 'pending',
                        text : 'Pending'
                    },
                    {
                        value : 'settlement',
                        text : 'Settlement'
                    },
                    {
                        value : 'expire',
                        text : 'Expire'
                    }
                ],
                transactionStatusSelected : 'pending',
            }
        },
        methods : {
            handleAction(item) {
                const url = this.$router.resolve({name : 'payment.simulation', query : {id_billing : item.order_id}});
                window.open(url.href, '_blank');
            },
        },

        computed : {
            additionalFilters() {
                return {
                    transaction_status : this.transactionStatusSelected
                }
            }
        }
    }
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12 col-12">
                <div class="card">
                    <b-form @submit.prevent>

                    <div class="card-body">
                        <h4 class="card-title mb-4">Billings</h4>
                        <b-form-group
                            class="mb-3"
                            label="Transaction Status"
                            label-for="select-transaction-status"
                            label-cols="12"
                            label-cols-sm="3"
                            content-cols-sm="9"
                            content-cols="12"
                        >
                            <b-form-select
                                class="form-select"
                                id="select-transaction-status"
                                v-model="transactionStatusSelected"
                                :options="transactionStatusOptions"
                            ></b-form-select>
                        </b-form-group>
                        
                        <DataTable :apiUrl="urlBackend + '/api/v1/billings/datatable'" :headers="headers" :isGroupsActions="true" :additionalFilters="additionalFilters">
                            <template #extraColumn="{ item, index }">
                            <button type="button" @click="handleAction(item, index)" class="btn btn-sm btn-primary">Detail</button>
                            </template>
                        </DataTable>
                    </div>
                    </b-form>

                </div>
            </div>
        </div>
    </Layout>
</template>
